$theme-color-primary: #4493ec;

$theme-text-color-primary: #252930;
$theme-text-color-secondary: lighten($theme-text-color-primary, 25%);
$theme-text-color-light: lighten($theme-text-color-primary, 40%);
$theme-bg-light: #f5f6fe;
$theme-border-color: lighten($theme-text-color-primary, 75%);
$theme-color-new: #5ab66e;
$theme-color-count: #ec776c;
$theme-error: #ec575b;
$smoky-white: #f5f5f5;

$success: #5cb377;
$warning: #eebf41;
$info: #5b99ea;
$danger: #d26d69;
$primary: #4493ec;

$theme-color-pdf: #da2d27;
$theme-color-word: #66a0fd;
$theme-color-ppt: #f4b400;
$theme-color-excel: #0da95f;
$theme-color-video: #935dc1;
$theme-color-zip: #252930;

$body-color: #474747;
$text-muted: #a8a8a8;

$min-contrast-ratio: 1;

$font-size-root: 0.9rem;
$btn-focus-width: 0;

$logo-img: '/assets/themes/default/img/logo.svg';
$mini-logo-img: '/assets/themes/default/img/mini_logo.svg';
