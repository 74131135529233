@import 'global';
/*

.btn {
  font-weight: 500;
  border-radius: $btn-border-radius;
  outline: 0 !important;
  white-space: nowrap;
}

*/

$margin-icon-multi: 1.7;

.btn {
  font-weight: 500;
  border-radius: $btn-border-radius;
  outline: 0 !important;
  white-space: nowrap;

  display: inline-flex;
  // justify-content: space-between;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;


  i {
    padding-top: $btn-padding-y;
    padding-bottom: $btn-padding-y;
  }

  &.btn-sm {
    i {
      padding-top: $btn-padding-y-sm;
      padding-bottom: $btn-padding-y-sm;
    }
  }

  &.btn-lg {
    i {
      padding-top: $btn-padding-y-lg;
      padding-bottom: $btn-padding-y-lg;
    }
  }

}


.btn-default {
  @include button-variant($white, darken($light, 10%), $body-color);
  background-color: $white;
  border-bottom-color: darken($light, 13%);
  @include box-shadow(0 1px 1px rgba(90, 90, 90, 0.3));
}

.btn-addon {
  i {
    margin-right: $margin-icon-multi * $btn-padding-x;
    display: flex;
    align-self: stretch;
    align-items: center;
    position: relative;
    padding: 0;

    &::after {
      content: '';
      display: block;
      background-color: rgba(0, 0, 0, 0.1);

      position: absolute;

      top: -100vh;
      bottom: -100vh;
      left: -100vw;
      right: -$margin-icon-multi * $btn-padding-x / 2;
    }
  }

  &.btn-sm {
    i {
      margin-right: $margin-icon-multi * $btn-padding-x-sm;
      &::after {
       right: -$margin-icon-multi * $btn-padding-x-sm / 2;
      }
    }
  }
  &.btn-lg {
    i {
      margin-right: $margin-icon-multi * $btn-padding-x-lg;
      &::after {
        right: -$margin-icon-multi * $btn-padding-x-lg / 2;
      }
    }
  }
  &.btn-default {
    i::after {
      background-color: transparent;
      border-right: 1px solid $border-color;
    }
  }
}




/*
.btn-icon {
  padding: 0 !important;
  text-align: center;
  width: 34px;
  height: 34px;
  fa-icon {
    top: -1px;
    position: relative;
    line-height: 34px;
  }
  &.btn-sm {
    width: 30px;
    height: 30px;
    fa-icon {
      line-height: 30px;
    }
  }
  &.btn-lg {
    width: 45px;
    height: 45px;
    fa-icon {
      line-height: 45px;
    }
  }
}

.btn-rounded {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  &.btn-lg {
    padding-left: 25px;
    padding-right: 25px;
  }
}
*/
/*
.btn {
  > fa-icon {
    &.pull-left,
    &.pull-right {
   //   line-height: $line-height-base;
    }
  }
}


.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
*/

/*

.btn-addon {
  i {
    position: relative;
    margin-right: 1.3 * $btn-padding-x;
    display: inline-block;
    height: 100%;

    &::after {
      content: '';
      display: block;
      background-color: rgba(0, 0, 0, 0.1);

      border-radius: $btn-border-radius 0 0 $btn-border-radius;

      position: absolute;

      top: calc(($btn-padding-y + 0.3rem) * -1);
      bottom: calc(($btn-padding-y - 0.3rem) * -1);
      left: calc(($btn-padding-x + 1px) * -1);
      right: calc(($btn-padding-x) * -0.7);
    }
  }

  &.btn-sm {
    i {
      margin-right: 1.3 * $btn-padding-x-sm;
      &::after {
        top: calc(($btn-padding-y-sm + 0.3rem) * -1);
        bottom: calc(($btn-padding-y-sm - 0.2rem) * -1);
        left: calc(($btn-padding-x-sm + 1px) * -1);
        right: calc(($btn-padding-x-sm + 3px) * -0.7);
      }
    }
  }
  &.btn-lg {
    margin-right: 1.3 * $btn-padding-x-lg;
    &::after {
      top: calc(($btn-padding-y-lg + 0.3rem) * -1);
      bottom: calc(($btn-padding-y-lg + 0.3rem) * -1);
      left: calc(($btn-padding-x-lg + 1px) * -1);
      right: calc(($btn-padding-x-lg) * -0.7);
    }
  }
  &.btn-default {
    i::after {
      background-color: transparent;
      border-right: 1px solid $border-color;
    }
  }
}

*/


/*

@media screen and (max-width: 992px) {
  .btn-hidden-xs-animate {

    padding-right: 0;
    @include transition(padding 0.5s ease-in-out 0s);

    &.btn-addon fa-icon {
      margin-right: 0;
      @include transition(margin 0.5s ease-in-out 0s);
    }

    .btn-name {
        margin-right: -300px;
        @include transition(margin 0.5s ease-in-out 0s);
    }

  }

  .btn-hidden-xs-animate:hover {
    padding-right: 12px;
    .btn-name {
      margin-right: 0;
    }

    &.btn-addon fa-icon {
      margin-right: 12px;
    }

  }
}



@mixin btn-addon_btn-collapse {


  overflow: hidden;
  padding-right: 0;
  @include transition(padding 0.5s ease-in-out 0s);

  &.btn-addon fa-icon {
    border-right: none;
    margin-right: 0;
    @include transition(margin 0.5s ease-in-out 0s);
  }

  .btn-name {
    margin-right: -300px;
    @include transition(margin 0.5s ease-in-out 0s);
  }


  &:hover {

    padding-right: 12px;
    .btn-name {
      margin-right: 0;
    }

    &.btn-addon fa-icon {
      border-right: inherit;
      margin-right: 12px;
    }

  }

}

.btn-addon.btn-collapse {
    @include btn-addon_btn-collapse();
}


@include media-breakpoint-only(sm) {
  .btn-addon.collapse-sm {
    @include btn-addon_btn-collapse();
  }
}

@include media-breakpoint-only(md) {
  .btn-addon.collapse-md {
    @include btn-addon_btn-collapse();
  }
}

*/
