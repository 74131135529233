@import 'variables';

body {
  font-family: 'Exo 2', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 300;
  font-optical-sizing: auto;
  font-style: normal;
}

.app {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: $theme-bg-light;
  overflow-x: hidden;
}

.app-footer {
  .copyright {
    font-size: 0.8125rem;
  }
}
