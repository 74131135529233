/* You can add global styles to this file, and also import other style files */

$enable-negative-margins: true;

/* Importing Bootstrap SCSS file. */

@import 'assets/themes/default/global';

@import '~bootstrap/scss/bootstrap';

@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import 'assets/themes/default/main';
